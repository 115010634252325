<template>
  <div class="xrayImg">
    <div class="imgW" id="gd_img_view">
      <img v-if="xrayMap?.rsltUrl && xrayMap?.modThumbNm"  v-ripple
           :src="getImageSrc(xrayMap.rsltUrl, xrayMap.modThumbNm)"
           @click="showDetail(xrayMap.rsltUrl + xrayMap.modNm)" />
    </div>
    <div class="txtW">
      <p class="date">촬영일: {{ xrayMap?.pictureDtStr || '' }}</p>

      <table class="cobbTbl">
        <caption></caption>
        <colgroup>
          <col style="width:10px;">
          <col />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Cobb's angle</th>
            <th>휜 방향</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>1</th>
            <td>{{ xrayMap?.firstAngle || '' }}˚</td>
            <td>{{ xrayMap?.firstDirection || '' }}</td>
          </tr>
          <tr v-if="xrayMap?.secondAngle">
            <th>2</th>
            <td>{{ xrayMap?.secondAngle || '' }}˚</td>
            <td>{{ xrayMap?.secondDirection || '' }}</td>
          </tr>
          <tr v-if="xrayMap?.thirdAngle">
            <th>3</th>
            <td>{{ xrayMap?.thirdAngle || '' }}˚</td>
            <td>{{ xrayMap?.thirdDirection || '' }}</td>
          </tr>
        </tbody>
      </table>
      <a class="dwnImg" title="이미지 다운로드" 
         :href="getDownloadUrl()" 
         download 
         id="gd_img_down">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clip-path="url(#clip0_989_5058)">
            <path d="M5 20H19V18H5V20ZM19 9H15V3H9V9H5L12 16L19 9Z" fill="#777777" />
          </g>
          <defs>
            <clipPath id="clip0_989_5058">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </a>
    </div>
    <v-dialog v-model="reportVisible" persistent>
      <spineReport :onClick="() => { reportVisible = false }" 
                   :xrayNo="xrayMap.no" 
                   @closeReport="closeReport"
                   :key="componentKey" />
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    xrayMap: {
      default() {
        return {
          no: '',
          rsltUrl: '',
          modNm: '',
          modThumbNm: '',
          pictureDt: '',
          firstAngle: 0,
          secondAngle: 0,
          thirdAngle: 0
        };
      },
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      reportVisible: false,
      componentKey: 0,
      pineDx: process.env.VUE_APP_PINE_DX,
      XRAY_TYPE_SPINE: "spine", // XRAY_TYPE_SPINE 변수 정의
    }
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    openReport(event) {
      event.preventDefault();
      this.componentKey += 1;
      this.reportVisible = true;
    },
    closeReport() {
      this.reportVisible = false;
    },
    getImageSrc(rsltUrl, modThumbNm) {
      return `${rsltUrl}${modThumbNm}`;
    },
    getDownloadUrl() {
      return `/api/file/${this.XRAY_TYPE_SPINE}/cobb/${this.xrayMap.modNm}?download=true`;
    },
    showDetail(url) {
      // 새로운 창에서 이미지를 엽니다.
      window.open(`/api/file/${this.XRAY_TYPE_SPINE}/cobb/${this.xrayMap.modNm}?download=false`, '_blank');
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../sass/app.scss";

.v-application {
  .totalReportView {
    position: absolute;
    right: 40px;
    top: 40px;
  }
}
</style>
